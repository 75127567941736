@use "./breakpoints" as *;

// GLIDEJS CAROUSEL
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

//TOPBAR
.topbar {
    background-color: rgb(var(--black));

    .switcher {
        p {
            color: rgb(var(--white));
        }

        .switch {
            input { 
                &:checked {
                    & + .slider {
                        border: solid 1px rgb(var(--white));
                    }
                }
            }
        }
    }

    .socials {
        li {
            min-width: 2rem;
            min-height: 2rem;

            a {
                &:hover {
                    svg {
                        color: rgb(var(--white)) !important;
                        transform: scale(1.3);
                    }
                }

                svg {
                    max-height: 0.8rem;
                }
            }
        }
    }

    // SWITCHER
    .switcher {
        display: none;

        &.locale-switcher {
            display: flex;
        }

        @media screen and (min-width: $media-lg) {
            display: flex;
        }

        p {
            color: rgb(var(--white));
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 2rem;
            height: 1rem;
            margin-top: 0 !important;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked {

                    & + .slider {
                        background-color: rgb(var(--black));
                        border: solid 1px rgb(var(--black));

                        &:before {
                            -webkit-transform: translateX(1rem);
                            -ms-transform: translateX(1rem);
                            transform: translateX(1rem);
                        }
                    }
                }

                &:focus + .slider {
                    box-shadow: 0 0 1px rgb(var(--black));
                }
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgb(var(--black));
                border: 1px solid #ccc;
                -webkit-transition: .4s;
                transition: .4s;

                &:before {
                position: absolute;
                content: "";
                height: 0.8rem;
                width: 0.8rem;
                left: 0;
                bottom: 0;
                background-color: rgb(var(--white));
                -webkit-transition: .4s;
                transition: .4s;
                }

                &.round {
                border-radius: 2rem;

                &:before {
                    border-radius: 50%;
                    }
                }
            }
        }
    }
}

//HERO
.hero {
    background-image: url("../images/hero_v2.webp");
    background-size: cover;
    background-position: center;
    margin-top: 0;
    pointer-events: none;

    img {
        width: 320px;

        @media screen and (min-width: $media-lg) {
            width: 580px;
        }
    }
}

// INTRO
.intro {
    .content p  {
        &:not(&:nth-last-child(-n + 2)) {
            font-family: var(--secondary);
            font-weight: var(--medium);
            line-height: 50px;
            background: linear-gradient(
              to right,
              rgba(var(--black), 1) 50%,
              rgba(var(--black), 0.2) 50%,
            );
            background-size: 200% 100%;
            background-position-x: 100%;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        &:nth-last-child(-n + 2) {
            font-family: var(--secondary);
            font-weight: var(--medium);
            line-height: 50px;
            background: linear-gradient(
            to right,
            rgba(var(--brown-light), 1) 50%,
            rgba(var(--brown-light), 0.2) 50%,
            );
            background-size: 200% 100%;
            background-position-x: 100%;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }
    }
}

// ENTREE PRO/PART
.choice {
    .card {
        &:hover {
            @media screen and (min-width: $media-md) {
                & > img {
                    scale: 1.2;
                }

                .btn {
                    padding-left: 2.25rem;
                    padding-right: 2.25rem;
                }
            }
        }
    }

    .middle-text p {
        writing-mode: vertical-rl;
    }

    &:hover {
        @media screen and (min-width: $media-md) {
            .card {
                &:first-child {
                    transform: translate(-3rem, 1rem);
                }
                &:last-child {
                    transform: translate(3rem, 1rem);
                }
            }

            .middle-text p {
                opacity: 1;
            }
        }
    }
}

// CERTIFICATIONS
.certifications {
    .quote {
        font-family: var(--secondary);
        font-weight: var(--medium);
        margin-bottom: 3rem;
        line-height: 50px;
    }
}

//QUOTE
.section-quote {
    &:hover {
        @media screen and (min-width: $media-md) {
            .content {
                opacity: 1;
                top: 56px;
            }

            .quote-logo {
                opacity: 0;
            }

            .quote-overlay {
                opacity: .4;
            }
    
            .quote-panel {
                width: 460px;
            }
    
            .quote-excerpt {
                opacity: 1;
            }
        }
    }
}

//SOCIALS
.socials {
    h2  {
        background: linear-gradient(
            to right,
            rgba(var(--brown-light), 1) 50%,
            rgba(var(--brown-light), 0.2) 50%,
        );
        background-size: 200% 100%;
        background-position-x: 100%;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
}